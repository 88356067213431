@use "sass:map";
@use "sass:color";
@use "variables";
$disabledBgColor: rgb(225, 225, 225);

.ant-layout {
  background-color: map.get($map: variables.$colors, $key: plannerBackground);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > .page-container {
    flex: 1;
  }
}

.ant-layout-header,
.ant-layout-footer,
.ant-menu-root {
  background: map.get(variables.$colors, secondary);
}

.ant-layout-footer {
  text-align: center;
  padding: 8px;
}

.ant-layout-content {
  background-color: map.get(variables.$colors, plannerBackground);
  padding: 29px 27px;

  @media only screen and (max-width: 576px) {
    padding: 29px 15px;
  }
}

.ant-layout-sider {
  background-color: map.get(variables.$colors, white);
  padding: 0px;
  text-align: center;
}

.ant-layout-content {
  background-color: map.get(variables.$colors, plannerBackground);
  height: 100%;
  overflow: hidden;
}

.ant-layout-header {
  padding: 0 25px;
  height: 50px;
}

.ant-modal {
  .ant-modal-content {
    border-radius: 0;
    .ant-modal-title,
    .ant-modal-close {
      color: map.get(variables.$colors, secondary);
    }
    .ant-modal-header {
      display: none;
      background: map.get(variables.$colors, primary);
    }
    .ant-modal-footer {
      border-top: 0;
    }
    div & .ant-btn {
      color: #b0b0b0;
      // height: 21px;

      &.button-fleks-ui {
        &[disabled] {
          color: map.get($map: variables.$colors, $key: white);
        }
      }
      &.ant-btn-primary:not(:disabled) {
        font-size: 12px;
        background-color: map.get(variables.$colors, action);
        color: white;

        &.button-fleks-ui {
          font-size: 12px;
          background-color: map.get($map: variables.$colors, $key: primaryUI);

          &.ant-btn-background-ghost {
            color: map.get($map: variables.$colors, $key: primaryUI);
            border: 1px solid map.get($map: variables.$colors, $key: primaryUI);
            min-width: 0;
          }

          &[disabled] {
            background: $disabledBgColor;
            background-color: $disabledBgColor;
          }
        }
      }
    }
  }
  .ant-modal-close-x {
    color: #b0b0b0;
  }
}

.ant-btn {
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  color: map.get(variables.$colors, secondary);
  border: none;
  padding: 0 5px;

  &.button-fleks-ui {
    border-radius: 20px;
    font-size: 12px;
    height: auto;
    min-width: 0;
    padding: 5px 30px;
    text-align: center;
    font-family: "AvenirNext-Regular";
    color: map.get(variables.$colors, secondary);
    border: none;
  }

  &.btn-action {
    background-color: map.get(variables.$colors, action);
  }

  &:hover,
  &:focus {
    border: none;
    color: map.get(variables.$colors, secondary);
  }
}

.ant-list-vertical .ant-list-item {
  margin: 0;
}
/* Tabs */
.ant-tabs {
  height: 100%;
  .ant-tabs-bar {
    margin-bottom: 0;
    border: none;
  }
  .ant-tabs-tabpane {
    border-radius: 7px;
    background-color: map.get(variables.$colors, secondary);
    overflow-y: auto;
  }
  & > .ant-tabs-content {
    height: 100%;

    & > .ant-tabs-tabpane.ant-tabs-tabpane-inactive {
      height: 0;
    }
  }

  .ant-tabs-nav {
    text-align: center;
  }

  .ant-tabs-tab {
    padding-bottom: 2px;
    color: #9e9e9e;
    font-family: "Roboto";
    font-size: 12px;

    &:hover {
      color: map.get(variables.$colors, primary);
    }
  }

  .ant-tabs-tab-active {
    color: #4a4a4a;
    font-size: 14px;
  }

  .ant-tabs-ink-bar {
    background-color: map.get(variables.$colors, primary);
  }

  &.ant-tabs-card {
    .ant-tabs-card-bar {
      .ant-tabs-tab {
        height: auto;
      }
    }
  }
}

/* Spin */
.ant-spin-dot.ant-spin-dot-spin i {
  background-color: map.get(variables.$colors, primary);
}
.ant-popover {
  z-index: 1000;

  .ant-popover-inner-content {
    width: 20em;

    .ant-popover-message {
      i {
        color: map.get(variables.$colors, primary);
      }
    }

    .ant-popover-buttons {
      button {
        min-width: 6em;
      }

      button {
        background-color: map.get(variables.$colors, darkgray2);
        color: map.get(variables.$colors, secondary);
      }

      button:last-child {
        background-color: map.get(variables.$colors, action);
      }
    }
  }
}

/* Dropdown */
.ant-select-dropdown {
  width: 127px;
  background-color: color.adjust(map.get(variables.$colors, darkgray2), $lightness: 10%);
  font-family: "Roboto";
  font-size: 10px;
  letter-spacing: 0px;
  color: #9b9b9b;
  .ant-select-dropdown-menu-item:hover {
    background-color: map.get(variables.$colors, primary);
  }
  .ant-select-dropdown-menu-item-active {
    background-color: map.get(variables.$colors, primary);
  }
}

/* Tags */
.ant-tag {
  height: 20px;
  border-radius: 2px;
  background-color: map.get(variables.$colors, primary);
  border: 1px solid map.get(variables.$colors, primary);
  font-family: "Roboto";
  font-size: 10px;
  color: map.get(variables.$colors, secondary);
  text-transform: capitalize;
  margin-bottom: 5px;
  i {
    float: right;
    font-size: 14px;
    height: 13px;
    font-weight: bold;
    padding-left: 5px;
    color: white !important;
    padding-top: 2px;
  }
}
