@use "sass:map";

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.block-before {
  &::before,
  &::after {
    content: none;
    display: block;
  }
}

.container {
  margin: 0 auto;
  max-width: 1170px;
}

/* .content{
  padding: 0 50px; 
  margin-top: $header-height;
} */

.margin-bottom {
  margin-bottom: 10px;
}

.margin-top {
  margin-top: 10px;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.padding-right {
  padding-right: 10px;
}

.padding-top {
  padding-top: 10px;
}

.no-margin {
  margin: 0 !important;
}

.margin-center {
  margin: 0 auto !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.right {
  float: right;
}

.bold {
  font-weight: 500;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.justify-align {
  text-align: justify;
}

.padding {
  padding: 24px !important;
}

.md-padding {
  padding: 12px !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.centered {
  left: 50%;
  transform: translateX(-50%);
}

.wrap-normal {
  white-space: normal;
}

.display-none {
  display: none;
}

.in-line {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.input:hover {
  ::placeholder {
    color: white;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: white;
  }
  ::-ms-input-placeholder {
    color: white;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 420px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.ant-select {
  .ant-select-selection__choice,
  .ant-select-selection__choice__remove {
    background-color: map.get($colors, primary);
    border: none;
    border-radius: 2px;
    color: map.get($colors, secondary);
  }
}

.ant-switch {
  &.ant-switch-checked {
    background-color: map.get($colors, primary);
  }
}

.save-button,
.save-button.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  background-color: map.get($colors, action);
  border: 1px solid map.get($colors, action);
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  height: 20px;
  //position: absolute;
  right: 0;
  min-width: 6em;

  i {
    background-color: inherit;
    color: inherit;
    height: auto;
    padding: 0;
    width: auto;
  }

  &.ant-btn-loading {
    min-width: 7em;
  }
}

/* Styles of form controls */
.ant-form-item {
  margin: 0;

  .ant-input {
    border: none;
  }

  .ant-form-item-label {
    color: inherit;
    text-align: left;
  }

  .ant-time-picker,
  .ant-select-selection,
  .ant-input {
    &:hover {
      background-color: rgba(47, 224, 194, 0.4);
      color: #4a4a4a;
    }

    &:focus {
      background-color: map.get($colors, secondary);
      box-shadow: 0 0 0 2px rgba(47, 224, 194, 0.44);
    }
  }

  .ant-calendar-picker,
  .ant-time-picker,
  .ant-select-selection,
  .ant-input {
    border-radius: 3px;
    font-size: 12px;
    width: 90%;
  }

  .ant-select-enabled {
    width: 45px;
  }

  .ant-calendar-picker {
    .ant-input {
      width: 100%;
    }
  }

  .ant-form-item-control {
    position: relative;

    .ant-form-explain {
      color: map.get($colors, action);
      font-size: 11px;
      left: 100%;
      position: absolute;
      top: 25%;
      width: 160px;
    }

    &.has-error {
      .ant-form-item-children {
        & input,
        .ant-select-selection {
          background-color: rgba(map.get($colors, action), 0.15);
          border: 0.8px solid rgba(map.get($colors, action), 1);
          box-shadow: none !important;
        }
      }
    }
  }

  .ant-select-arrow {
    display: none;
  }

  &.explain-bottom .ant-form-explain {
    left: 0;
    top: 100%;
    width: 100%;
  }

  .ant-input,
  .ant-calendar-picker {
    width: 100%;
  }

  .ant-input {
    border: 1px solid map.get($colors, grayBorder);
  }

  .ant-select {
    border: none;
    width: 100%;

    .ant-select-selection {
      border: 1px solid map.get($colors, grayBorder);
      width: 100%;
    }

    .ant-select-arrow {
      border-left: 1px solid map.get($colors, grayBorder);
      display: block;
      height: 100%;
      margin-top: 0;
      right: 0;
      top: 0;
      width: 2.3em;

      i {
        padding-top: 10px;
      }
    }
  }
  .ant-input::placeholder,
  .ant-time-picker-input::placeholder,
  .ant-input-number-input::placeholder,
  .ant-select-selection__placeholder,
  .ant-calendar-range-picker-input::placeholder {
    color: map.get($map: $colors, $key: whiteGray);
  }
}

.has-error {
  & input,
  & textarea,
  .ant-select-selection {
    background-color: rgba(map.get($colors, action), 0.15);
    border-color: rgba(map.get($colors, dangerUI), 1) !important;
    border-bottom-width: 2px !important;
    box-shadow: none;
  }
}

.phases {
  .fas {
    margin: 0 3px;
    font-size: 11px;
    width: 11px;
    height: 11px;
  }
  .fa-arrow-right {
    margin: 0;
    font-size: 9px;
  }
}

.adjust-text {
  margin-top: 20px;

  & > span {
    font-family: "AvenirNext-Regular";
    font-size: 12px;
    color: map.get($colors, primaryUIActive);

    &:hover {
      text-decoration: underline;
    }
  }
}
