@use "sass:map";
@use "../styles/variables";

$cubicBezier: ease-in-out;
$slideDuration: 400ms;

$base-skeleton-color: map.get(
  $map: variables.$colors,
  $key: white,
);
$shine-skeleton-color: map.get(
  $map: variables.$colors,
  $key: plannerHeaderBorder,
);
.page-container {
  background-color: map.get($map: variables.$colors, $key: plannerBackground);
  overflow: hidden;
  position: relative;
}

.slide-enter {
  bottom: 0;
  left: 100%;
  opacity: 0;
  position: absolute;
  right: -100%;
  top: 0;
}
.slide-enter-active {
  animation: slideEnterToLeft $slideDuration $cubicBezier;
  left: 0;
  opacity: 1;

  right: 0;
}
.slide-exit {
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  * {
    overflow-y: hidden !important;
  }
}
.slide-exit-active {
  animation: slideExitToLeft $slideDuration $cubicBezier;
  opacity: 0;
  left: -100%;
  right: 100%;
}
.rotate {
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes slideExitToLeft {
  0% {
    opacity: 1;
    left: 0;
    right: 0;
  }
  25% {
    opacity: 0.75;
    left: -25%;
    right: 25%;
  }

  50% {
    opacity: 0.5;
    left: -50%;
    right: 50%;
  }

  75% {
    opacity: 0.25;
    left: -75%;
    right: 75%;
  }

  100% {
    opacity: 0;
    left: -100%;
    right: 100%;
  }
}
@keyframes slideEnterToLeft {
  0% {
    opacity: 0;
    left: 100%;
    right: -100%;
  }
  25% {
    opacity: 0.25;
    left: 75%;
    right: -75%;
  }

  50% {
    opacity: 0.5;
    left: 50%;
    right: -50%;
  }

  75% {
    opacity: 0.75;
    left: 25%;
    right: -25%;
  }

  100% {
    opacity: 1;
    left: 0;
    right: 0;
  }
}
@mixin skeleton-animation {
  animation: shine-lines 1.6s infinite linear;
  background-image: linear-gradient(
    90deg,
    $base-skeleton-color 0px,
    $shine-skeleton-color 40px,
    $base-skeleton-color 80px
  );
  background-size: 600px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40% {
    background-position: 200px;
  }
  100% {
    background-position: 200px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
