/* Colors */
$colors: (
  primary: #28c3a9,
  primaryGradient: linear-gradient(260deg, #8251e3, #804fe2 12%, #4b28c3),
  primaryLight: rgba(40, 195, 169, 0.03),
  primaryLight2: rgba(91, 233, 210, 0.3),
  primaryShadow: rgba(40, 195, 169, 0.5),
  primaryUI: rgb(75, 40, 195),
  primaryUIShadow: rgba(75, 40, 195, 0.5),
  primaryUIActive: #4b28c3,
  primaryUILight: #c7bdec,
  primaryUILight2: rgb(108, 82, 204),
  primaryNoActive: rgb(218, 211, 242),
  dangerUI: rgb(195, 40, 40),
  warningUI: #d98b56,
  labelTextColor: rgb(103, 103, 103),
  labelFilterTextColor: #a7a7a7,
  inputBorder: #676767,
  shadow: rgba(206, 206, 206, 0.5),
  shadowJobCard: rgba(203, 203, 203, 0.25),
  shadowJobDetail: rgba(205, 205, 205, 0.25),
  shadowHoverJob: rgba(103, 103, 103, 0.15),
  shadowJobStats: rgba(203, 203, 203, 0.15),
  secondary: #ffffff,
  action: #e15d68,
  gray: #f5f5f5,
  whiteF8: #f8f8f8,
  whiteEA: #eaeaea,
  darkgray: #e4e4e4,
  darkgray2: #d8d8d8,
  darkergray: #454545,
  green: green,
  orange: orange,
  red: red,
  link: #40a9ff,
  white: #ffffff,
  secondaryWhite: #fafafa,
  yellow: #fcc829,
  black: black,
  black54: #545454,
  lightblack: #4e4e4e,
  lightGray: #d3d3d3,
  neutralBlack: #4a4a4a,
  grayBackground: #b0b0b0,
  grayBoldCard: #727272,
  blueSky: #69f4ea,
  fuchsia: #eb6cdc,
  unavailable: #fab1b5,
  wantsWork: #b5aada,
  available: #b7d4b7,
  transparent: transparent,
  grayBorder: #b0b0b0,
  borderSection: #97979780,
  plannerHeaderBorder: #f5f5f5,
  plannerBackground: #fcfbfe,
  pendingSpot: #56d996,
  pendingSpotBright: #5ed26c,
  notAvailable: #d25e5e,
  selectedGreen: #e6f5f5,
  whiteGray: #8e8e8e,
  ownShared: #56a5d9,
  sharedToMe: #d2975e,
  invited: #9a7654,
  purple: #a428c3,
  blackGRay: #605f63,
  unknownAvailability: #e3e3e4,
  greenAvailability: #ace6b4,
  redAvailability: #e6acad,
  noContractSchedulerCell: #f0f0f0,
);
