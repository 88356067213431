.page-404-info,
.page-404-image {
  max-width: 370px;
  padding: 20px;
}

.page-404-info {
  max-width: 400px;

  a,
  p {
    font-size: 0.75em;
  }

  h1 {
    font-size: 1.875em;
  }
}
