@use "sass:map";
@use "sass:color";
@use "../../../node_modules/antd/dist/antd";
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "./variables";
@import "./common";
@import "./andtRewrites";
@import "./animations";

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("../fonts/AvenirNext-DemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AvenirNext-Medium";
  font-weight: 500;
  src: url("../fonts/AvenirNext-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AvenirNext-Regular";
  src: url("../fonts/AvenirNext-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AvenirNext-Italic";
  src: url("../fonts/AvenirNext-Italic.ttf");
  font-display: swap;
}

* {
  font-family: "AvenirNext-Regular";
}

font {
  font-family: inherit;
}

html,
body {
  height: 100%;
  margin: 0px;
  font-size: 16px;
}

#app,
[data-reactroot] {
  height: 100%;

  & > div {
    height: 100%;
  }
}

a {
  color: #3c85bb;
  text-decoration: underline;
}

h1 {
  font-family: "Roboto";
  font-size: 25px;
  color: #4a4a4a;
  margin: 0;
}

p {
  margin: 0;
}

ul {
  padding: 0;
}

/* Change the appearance of the scrollbar */
::-webkit-scrollbar {
  width: 4px;
  background-color: #f6f4fc;
  position: relative;
  border-radius: 3px;
  cursor: grab;
}

::-webkit-scrollbar-thumb:vertical {
  background: #dcd5f3;
  border-radius: 3px;
  width: 4px;
  cursor: grab;

  &:active,
  &:hover {
    background: #4b28c3;
  }
}

/* Hide input number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* buttons */
button.btn-action,
.btn-action {
  background-color: map.get($colors, action);
  color: map.get($colors, secondary) !important;
  margin-left: 5px;
  padding: 0 5px;

  &:disabled,
  &[disabled]:hover {
    background-color: gainsboro;
  }
}

.btn-action {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.single-save-button {
  position: absolute !important;
  bottom: 0;
  right: 0;
  padding: 5px 25px;
  min-height: 25px !important;
}

.btn-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 5px;
}

/* Inputs */
.input-borderless.ant-input {
  width: 97%;
  height: 24px;
  background-color: map.get($colors, secondary);
  border-radius: 3px;
  border: none;
  font-size: 10px;

  &:focus {
    box-shadow: none;
  }
}

.input-primary {
  outline: none;
  box-shadow: none;

  &:focus,
  input:focus {
    border: none;
    -webkit-box-shadow: 0 0 0 2px
      color.adjust(map.get($colors, primary), $alpha: -0.44);
    box-shadow: 0 0 0 2px color.adjust(map.get($colors, primary), $alpha:  -0.44);
  }

  &:hover,
  input:hover {
    border: none;
    background-color: color.adjust(map.get($colors, primary), $alpha: -0.5);

    &::placeholder {
      color: white;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: white;
    }

    &::-ms-input-placeholder {
      color: white;
    }
  }
}

.employee-image {
  img {
    border-radius: 50% !important;
    object-fit: contain;
  }
}

.hour-name {
  display: flex;
  align-items: center;

  .hour-icons {
    display: flex;
    align-items: center;
    text-align: center;

    i.fas {
      margin-left: 5px;
      display: inline-block;
      color: white;
      border-radius: 50%;
      width: 2em;
      box-shadow: 0px 0px 2px #888;
      padding: 0.5em 0.5em;
    }

    i.normal {
      background-color: map.get($colors, primary);
    }

    i.danger {
      background-color: map.get($colors, action);
    }
  }
}

.link {
  cursor: pointer;
  color: #4a4a4a;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #4a4a4a;
  }
}

.underline {
  text-decoration: underline;
}

.underline-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.no-link {
  &,
  &:active,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
.no-link-u {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}

.there-is-picture,
.there-is-no-picture {
  cursor: pointer;
}

.there-is-picture {
  color: map.get($colors, primary);
}

.there-is-no-picture {
  color: map.get($colors, action);
}

.formated-hours {
  display: flex;
  align-items: center;

  .arrow-hour {
    margin-left: 3px;
    margin-right: 3px;

    .fa-arrow-down {
      color: map.get($colors, action);
    }

    .fa-arrow-up {
      color: map.get($colors, primary);
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: map.get($map: $colors, $key: primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: map.get($map: $colors, $key: primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: map.get($map: $colors, $key: primary);
}

.ant-checkbox-checked::after {
  border: none;
}

.ant-calendar-today .ant-calendar-date {
  border-color: map.get($colors, primary);
  color: map.get($colors, primary);
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  background-color: map.get($colors, primary);
  color: map.get($colors, secondary);

  &:hover {
    background-color: map.get($colors, primary);
    color: map.get($colors, secondary);
  }
}

.ant-calendar-year-panel-year,
.ant-calendar-date,
.ant-calendar-selected-date,
.ant-calendar-month-panel-month,
.ant-calendar-decade-panel-decade {
  &:hover {
    background-color: rgba(map.get($colors, $key: primary), 0.2);
  }
}

.ant-calendar-header {
  a:hover {
    color: rgba(map.get($colors, $key: lightblack), 0.4);
  }
}

/*Google Maps input*/
.map-input-search {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  margin: 5px;
  opacity: 0.9;
  top: 200px;
}

.ant-steps {
  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: #4a4a4a;
    font-weight: bold;
  }

  .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
    color: #4a4a4a;

    &::after {
      background-color: map.get($map: $colors, $key: primary);
    }
  }

  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: map.get($map: $colors, $key: primary);
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: map.get($map: $colors, $key: primary);

    .ant-steps-icon {
      color: map.get($map: $colors, $key: secondary);
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: map.get($map: $colors, $key: secondary);

    .ant-steps-icon {
      color: map.get($map: $colors, $key: primary);
    }
  }
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.a-ease-15 {
  transition: all ease 0.15s;
}

*:not(a).nlink {
  color: map.get($key: white, $map: $colors) !important;

  &:hover {
    color: map.get($key: neutralBlack, $map: $colors) !important;
  }
}

.animation-hover-shadow-up {
  position: relative;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: 0;

  &:hover {
    box-shadow: 4px 4px 10px 0
      rgba(map.get($map: $colors, $key: shadowHoverJob), 0.15);
    transform: translateY(-3px);
  }
}

.avenir-next-demi-bold {
  font-family: "AvenirNext-DemiBold", sans-serif;
  font-weight: 800;
}

.avenir-next-medium {
  font-family: "AvenirNext-Medium", sans-serif;
  font-weight: 500;
}

.avenir-next-regular {
  font-family: "AvenirNext-Regular", sans-serif;
}

.avenir-next-italic {
  font-family: "AvenirNext-Italic", sans-serif;
}

@for $i from 1 to 52 {
  .fz-#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 0 to 71 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px;
  }

  .margin-top-#{$i} {
    margin-top: #{$i}px;
  }

  .margin-right-#{$i} {
    margin-right: #{$i}px;
  }

  .margin-left-#{$i} {
    margin-left: #{$i}px;
  }

  .padding-#{$i} {
    padding: #{$i}px;
  }

  .padding-bottom-#{$i} {
    padding-bottom: #{$i}px;
  }

  .padding-top-#{$i} {
    padding-top: #{$i}px;
  }

  .padding-right-#{$i} {
    padding-right: #{$i}px;
  }

  .padding-left-#{$i} {
    padding-left: #{$i}px;
  }

  .top-#{$i} {
    top: #{$i}px;
  }

  .right-#{$i} {
    right: #{$i}px;
  }

  .bottom-#{$i} {
    bottom: #{$i}px;
  }

  .left-#{$i} {
    left: #{$i}px;
  }
}

/* Colors */
.inputBorder-color {
  color: map.get($map: $colors, $key: inputBorder);
}

.neutralBlack-color {
  color: map.get($map: $colors, $key: neutralBlack);
}

.whiteGray-color {
  color: map.get($map: $colors, $key: whiteGray);
}

/* Classes are generated from the colors */
@each $name, $color in $colors {
  .#{"" + $name}-bg {
    background-color: $color;

    &:hover,
    &:active {
      background-color: $color;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .#{$name} {
    color: $color;

    &:hover,
    &:active {
      color: $color;
    }
  }
}
